import React from 'react';

export function LoadingSpinner() {
  return (
    <div className="flex items-center justify-center" role="status">
      <svg
        className="animate-draw"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24 4C12.954 4 4 12.954 4 24s8.954 20 20 20 20-8.954 20-20S35.046 4 24 4z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          pathLength="1"
        />
        <path
          d="M24 12v12l8 8"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          pathLength="1"
        />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  );
}